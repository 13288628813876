import { computed, markRaw } from 'vue';
import { string, mixed, date, setLocale, number } from 'yup';
import es from 'yup-es';
import { rutFormat, rutValidate } from 'rut-helpers';
import BaseInput from '../components/base-input.vue';
import BaseSelect from '../components/base-select.vue';
import BaseFileInput from '../components/base-file-input.vue';
import BaseRadioGroup from '../components/base-radio-group.vue';
import BaseCheckboxGroup from '../components/base-checkbox-group.vue';
import useRemoteOptions from './remoteOptions'; // eslint-disable-next-line no-magic-numbers
const PHONE_NUMBER_LENGTH = 8;
setLocale(es);
const inputTypes = { text: { id: 'text', name: 'Texto', valueType: 'single', component: markRaw(BaseInput), validation: string(), attrs: { type: 'text' }, encryptable: true }, email: { id: 'email', name: 'Correo electrónico', valueType: 'single', component: markRaw(BaseInput), validation: string().email(), attrs: { type: 'email' }, encryptable: true }, rut: { id: 'rut', name: 'RUT', valueType: 'single', component: markRaw(BaseInput), watch(field) { return (newValue, oldValue) => { if (newValue && newValue !== oldValue) {
            field.value = rutFormat(newValue);
        } }; }, validation: string().test(value => { if (value) {
            return rutValidate(value);
        } return false; }), attrs: { type: 'text' }, encryptable: true }, phone: { id: 'phone', name: 'Teléfono', valueType: 'single', component: markRaw(BaseInput), slots: [{ name: 'prefix', content: '+56 9' }], validation: string().matches(/^[0-9]+$/, 'El campo no es válido').min(PHONE_NUMBER_LENGTH).max(PHONE_NUMBER_LENGTH), attrs: { type: 'tel' }, encryptable: true }, dropdown: { id: 'dropdown', name: 'Dropdown / Select', valueType: 'multiple', component: markRaw(BaseSelect), validation: string() }, file: { id: 'file', name: 'Archivo', valueType: 'file', component: markRaw(BaseFileInput), validation: mixed() }, date: { id: 'date', name: 'Fecha', valueType: 'date', component: markRaw(BaseInput), validation: date(), attrs: { type: 'date' }, encryptable: true }, checkbox: { id: 'checkbox', name: 'Checkbox', valueType: 'multiple', component: markRaw(BaseCheckboxGroup), validation: mixed() }, radio: { id: 'radio', name: 'Radio', valueType: 'multiple', component: markRaw(BaseRadioGroup), validation: string() }, region: { id: 'region', baseId: 'dropdown', name: 'Región', valueType: 'multiple', component: markRaw(BaseSelect), validation: number(), defaultBuilderValues: { dataUrl: '/api/internal/regions', dataKey: 'regions' } }, commune: { id: 'commune', baseId: 'dropdown', name: 'Comuna', valueType: 'multiple', component: markRaw(BaseSelect), validation: number(), defaultBuilderValues: { dataUrl: '/api/internal/communes', dataKey: 'communes' } }, locality: { id: 'locality', baseId: 'dropdown', name: 'Localidad', valueType: 'multiple', component: markRaw(BaseSelect), validation: number(), defaultBuilderValues: { dataUrl: '/api/internal/localities', dataKey: 'localities' } },
    rut_account: { id: 'rut_account', name: 'Cuenta RUT', valueType: 'single', component: markRaw(BaseInput), watch(field) { return (newValue, oldValue) => { if (newValue && newValue !== oldValue) {
            if (rutValidate(newValue) && /\D/.test(newValue)) {
                field.value = newValue.split('-')[0].replace(/\D/g, '');
            }
        } }; }, validation: string(), attrs: { type: 'text' }, encryptable: true, defaultBuilderValues: { defaultValue: 'user_rut' } } };
function getFormFields(formValues) { return computed(() => { const ffields = formValues.formSteps?.reduce((fields, step) => { fields.push(...step.formFields); return fields; }, []); return ffields; }); }
const conditionOperators = { '==': {
        evaluate: (a, b) => a == b, label: 'Es igual a', isComparison: true
    }, '!=': {
        evaluate: (a, b) => a != b, label: 'Es diferente a', isComparison: true
    }, '>': { evaluate: (a, b) => Number(a) > Number(b), label: 'Es mayor a', isComparison: true }, '<': { evaluate: (a, b) => Number(a) < Number(b), label: 'Es menor a', isComparison: true }, '>=': { evaluate: (a, b) => Number(a) >= Number(b), label: 'Es mayor o igual a', isComparison: true }, '<=': { evaluate: (a, b) => Number(a) <= Number(b), label: 'Es menor o igual a', isComparison: true }, 'empty': { evaluate: a => !a, label: 'Está vacío', isComparison: false }, 'not_empty': { evaluate: a => !!a, label: 'No está vacío', isComparison: false } };
const { remoteOptions } = useRemoteOptions();
function getSelectedRemoteOptionText(fieldName, value) {
    return remoteOptions[fieldName]?.find(option => option.id == value)?.name;
} // eslint-disable-next-line max-statements, complexity
function parseCondition(condition, parsedFields) { const fieldToCompare = parsedFields[condition.field]; if (fieldToCompare) {
    let fieldToCompareValue = fieldToCompare.value;
    if (fieldToCompare.formFieldOptions && fieldToCompare.formFieldOptions.length > 0) {
        fieldToCompareValue = fieldToCompare.formFieldOptions /* eslint-disable eqeqeq */.find(option => { if (Array.isArray(fieldToCompare.value)) {
            return fieldToCompare.value.some(val => option.id == val) && option.value == condition.value;
        } return fieldToCompare.value === option.id && option.value == condition.value; })?.value; /* eslint-enable eqeqeq */
    }
    if (fieldToCompare.dataUrl) {
        fieldToCompareValue = getSelectedRemoteOptionText(fieldToCompare.name, fieldToCompareValue) || fieldToCompareValue;
    }
    return conditionOperators[condition.operator].evaluate(fieldToCompareValue, condition.value);
} return true; }
function parseConditions(conditions, fields) { const { and, or } = conditions || {}; if (and) {
    return and.every(condition => { if (condition && Object.keys(condition).includes('field')) {
        return parseCondition(condition, fields);
    } return parseConditions(condition, fields); });
} if (or) {
    return or.some(condition => { if (condition && Object.keys(condition).includes('field')) {
        return parseCondition(condition, fields);
    } return parseConditions(condition, fields); });
} return true; }
const validations = { required: { label: 'Requerido', schema: (val, yupSchema, parsedFields) => { const validate = typeof val === 'boolean' ? val : parseConditions(val, parsedFields); return validate ? yupSchema.transform((value, originalValue) => originalValue ? value : undefined).nullable().required() : yupSchema; } }, '==': { label: 'Debe ser igual a', schema: (ruleValue, yupSchema, parsedField) => yupSchema.test('custom-one-of', ({ label }) => `${label} debe ser igual a ${ruleValue}`, value => {
            const options = parsedField?.formFieldOptions;
            if (options && options.length > 0 && !parsedField?.dataUrl) { // eslint-disable-next-line eqeqeq
                return options.filter(option => option.value === ruleValue).some(option => option.id == value);
            }
            if (parsedField?.dataUrl) {
                return getSelectedRemoteOptionText(parsedField.name, value) === ruleValue;
            } // eslint-disable-next-line eqeqeq
            return value == ruleValue;
        }) }, '!=': { label: 'Debe ser diferente a', schema: (ruleValue, yupSchema, parsedField) => yupSchema.test('custom-not-one-of', ({ label }) => `${label} no debe ser igual a ${ruleValue}`, value => {
            const options = parsedField?.formFieldOptions;
            if (options && options.length > 0) { // eslint-disable-next-line eqeqeq
                return options.filter(option => option.value === ruleValue).every(option => option.id != value);
            }
            if (parsedField?.dataUrl) {
                return getSelectedRemoteOptionText(parsedField.name, value) !== ruleValue;
            } // eslint-disable-next-line eqeqeq
            return value != ruleValue;
        }) } };
export default function useFormBuilder() { return { inputTypes, getFormFields, conditionOperators, validations, parseConditions, getSelectedRemoteOptionText }; }
