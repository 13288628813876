import { reactive, ref } from 'vue';
import axios from 'axios';
const remoteOptions = reactive({});
export default function useRemoteOptions() { const loading = ref(false); const error = ref(false); async function getRemoteOptions(fieldName, dataUrl, dataKey) { try {
    loading.value = true;
    const { data } = await axios.get(dataUrl);
    remoteOptions[fieldName] = dataKey ? data[dataKey] : data;
    return remoteOptions[fieldName];
}
catch (e) {
    error.value = true;
    return [];
}
finally {
    loading.value = false;
} } return { remoteOptions, getRemoteOptions, loading, error }; }
